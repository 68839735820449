import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


const Licensing = (props) => {

    const [style, setStyle] = useState("custom-spinner")
    const [backToIndex, setBackToIndex] = useState(false)
    const [tenantList, setTenantList] = useState([])
    const [selectedTenant, setSelectedTenant] = useState("")
    const [licenses, setLicenses] = useState({
        vcx_desktop: '0',
        vcx_mobile: '0',
        call_monitor: '0',
        call_recording: '0',
        analytics: '0',
    })

    const headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    useEffect (()=> {
        if (JSON.parse(localStorage.getItem('creds')).user_type !== "admin") {
            setBackToIndex(true);
            return
        }
        
        setStyle("custom-spinner")
        let payLoad = {}
        axios.post(`${process.env.REACT_APP_API_URL}tenantList`, payLoad, headers)
        .then(response=>{
            const tenants = (response.data).sort(getSortOrder("tname"));
            populateTenants(tenants);
            setStyle("custom-spinner-hide")
        })
        .catch(err=>{
            toast.error(err.message);
            setStyle('custom-spinner-hide')
        });
    },[])

    const getSortOrder = (prop) => {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    const populateTenants = (tenants) => {
        let items = [];
            tenants.map((row, index)=>{
                const value = {tenant: row.tname, tenantId: row.tid, desktopAppAllowed: row.desktop_app_allowed, mobileAppAllowed: row.mobile_app_allowed}
                items.push(value);
                return null
            });
            setTenantList(items)
    }

    const tenantChangeHandler = (result) => {
        if(result.length === 0){
            setSelectedTenant("")
            return;
        }
        setSelectedTenant(result[0].tenantId.toString())
        setLicenses({});
        getLicenses(result[0].tenantId.toString())

    }

    const getLicenses =(tenantId) => {
        let payLoad = {
            tenant_id: tenantId
        }
        axios.post(`${process.env.REACT_APP_API_URL}getLicenses`, payLoad, headers)
        .then(response=>{
            setLicenses(response.data)
            setStyle('custom-spinner-hide')
        })
        .catch(err=>{
            toast.error(err.message);
            setStyle('custom-spinner-hide')
        });
    }

    const inputChangeHandler = (evt) => {
    
        setLicenses(prevState => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }))
    }

    const saveHandler = () => {
        console.log(licenses)
        if (!licenses.vcx_desktop || !licenses.vcx_mobile 
            || !licenses.call_monitor || !licenses.call_recording 
            || !licenses.analytics){

            toast.error("All Fields are mandatory!")
            return;
        }

        setStyle('custom-spinner')
        let payLoad = licenses;
        payLoad["tenant_id"] = selectedTenant;
        payLoad["last_updated_by"] = JSON.parse(localStorage.getItem('creds')).id;
        axios.post(`${process.env.REACT_APP_API_URL}createUpdateLicenses`, payLoad, headers)
        .then(response=>{
            toast.success("Successfully saved data!")
            getLicenses(selectedTenant)
        })
        .catch(err=>{
            toast.error(err.message);
            setStyle('custom-spinner-hide')
        });
    }


    let redirect = null;

    if(backToIndex){
        redirect = <Redirect to = {{
                pathname: "/dashboard"
        }} />;
    }
    return(
        
        <Fragment>
                <Breadcrumb parent="App" title="Licenses" />
                <div className={"loader-box " + style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}
                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Choose Temant</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="theme-form" style={{width: "calc(100% - 90px)"}}>
                                    <div className="form-group">
                                        <i className="fa fa-search type-ahead-search-icon"/>
                                        <Typeahead
                                            id="basic-typeahead"
                                            className="search-domains"
                                            labelKey="tenant"
                                            multiple={false}
                                            options={tenantList}
                                            onChange={tenantChangeHandler}
                                            placeholder="Choose a tenant..."
                                        />
                                    </div>
                                    
                                </form>
                            </div>
                        </CardBody>
                    </Card>

                    
                    <div className="col-md-1 col-sm-0"></div>
                </div>

                {
                    selectedTenant? 
                        <div className="container-fluid main-container">
                            <div className="col-md-1 col-sm-0"></div>
                            <Card className="col-md-10 col-sm-12">
                                <CardHeader>
                                    <h5>Set Limits</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div className="row form-group">
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                V-CX Desktop
                                            </label> <span> * </span>
                                            <input type="number" className="form-control" name="vcx_desktop"
                                                min="0"
                                                onChange={inputChangeHandler} value={licenses.vcx_desktop}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                V-CX Mobile
                                            </label> <span> * </span>
                                            <input type="number" className="form-control" name="vcx_mobile"
                                                min="0"
                                                onChange={inputChangeHandler} value={licenses.vcx_mobile}/>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Call Monitor
                                            </label> <span> * </span>
                                            <input type="number" className="form-control" name="call_monitor"
                                                min="0"
                                                onChange={inputChangeHandler} value={licenses.call_monitor}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Call Recording
                                            </label> <span> * </span>
                                            <input type="number" className="form-control" name="call_recording"
                                                min="0"
                                                onChange={inputChangeHandler} value={licenses.call_recording}/>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Analytics
                                            </label> <span> * </span>
                                            <input type="number" className="form-control" name="analytics"
                                                min="0"
                                                onChange={inputChangeHandler} value={licenses.analytics}/>
                                        </div>
                                    </div>
                                    <button type="button" onClick={saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>
                                </CardBody>
                            </Card>

                            
                            <div className="col-md-1 col-sm-0"></div>
                        </div>

                    : null
                }
                
                
                

            </Fragment>

    );
}

export default Licensing;