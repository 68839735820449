import {
    Home,
    Users,
    Globe,
    FileText,
    Clipboard,
    Droplet,
    Lock,
    Mail,
    Smartphone
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: true, path: '/dashboard'
    },
    {
        title: 'Resellers', icon: Globe, type: 'link', active: false, path: '/resellers'
    },
    {
        title: 'Reseller Users', icon: Droplet, type: 'link', active: false, path: '/reseller_users'
    },
    {
        title: 'Tenants', icon: Smartphone, type: 'link', active: false, path: '/tenants'
    },
    {
        title: 'Users', icon: Users, type: 'link', active: false, path: '/users'
    },
    {
        title: 'Client Profiles', icon: Clipboard, type: 'link', active: false, path: '/client_profiles'
    },
    {
        title: 'Licenses', icon: Lock, type: 'link', active: false, path: '/licenses'
    },
    {
        title: 'Email Templates', icon: Mail, type: 'link', active: false, path: '/email_templates'
    },
    {
        title: 'Sytem Logs', icon: FileText, type: 'link', active: false, path: '/logs'
    },
    // {
    //     title: 'Domains', icon: Globe, type: 'link', active: false, path: '/domains'
    // },
]
