import React, { useState, useEffect, Fragment } from 'react';
import logo from '../assets/images/vtsl_logo_black.png';
import tickMark from '../assets/images/Tick_Mark_Circle.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";

import '../assets/scss/app-styles/signin.scss'

import axios from "axios";
import { RESETPASSWORD,NewPassword,RetypePassword,Reset} from "../constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-solid-svg-icons'



const ResetAppPassword = ({ history }) => {

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);

    const [error, setError] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const resetPassword = (evt) => {
        evt.preventDefault();
        const url = new URL(window.location.href);
        const ref = url.searchParams.get("ref");
        if(!newPassword || !confirmPassword){
            setError("Please fill both fields")
            return;
        }
        if(newPassword.length < 8){
            setError("New Password should be atleast 8 characters long!")
            return;
        }
        if(newPassword !== confirmPassword){
            setError("Passwords doesnot match")
            return;
        }
        if(passwordStrength < 4){
            // setError('Password should have atleast 1 of $@#&! special character, 1 number, 1 lower case letter and 1 upper case letter!');
            setError('Password is weak');
            return;
        }
        const payLoad = {
            ref: encodeURIComponent(ref),
            password: newPassword
        }
        axios.post(`${process.env.REACT_APP_API_URL}resetAppPassword`, payLoad)
        .then(response=>{
            setResetSuccess(true);
            //toast.info("Your password has been reset. Please go back to your V-CX Application to log back in!");
        })
        .catch(err=>{
            toast.error(err.response.data)
        });
    }

    const passwordKeyUpHandler = (evt) => {
        var strengthbar = document.getElementById("meter");
        const pass = evt.target.value;
        setError('');
        var strength = 0;
        if (pass.match(/[a-z]+/)) {
            strength += 1;
        }
        if (pass.match(/[A-Z]+/)) {
            strength += 1;
        }
        if (pass.match(/[0-9]+/)) {
            strength += 1;
        }
        if (pass.match(/[$@#&!]+/)) {
            strength += 1;

        }

        if (pass.length < 8) {
            setError('Minimum number of characters are 8');
            strength -= 1;
        }
        if (pass.length > 100) {
            setError('Maximum number of characters are 100');
            strength -= 1;
        } 
        // if (pass.length >=8 && pass.length <= 100){
            
        // }

        switch (strength) {
            case 0:
            strengthbar.style.width = '0%';
            strengthbar.style.backgroundColor = "red";
            break;

            case 1:
            strengthbar.style.width = '25%';
            strengthbar.style.backgroundColor = "red";
            break;

            case 2:
            strengthbar.style.width = '50%';
            strengthbar.style.backgroundColor = "orange";
            break;

            case 3:
            strengthbar.style.width = '75%';
            strengthbar.style.backgroundColor = "orange";
            break;

            case 4:
            strengthbar.style.width = '100%';
            strengthbar.style.backgroundColor = "#22af47";
            break;
        }

        setPasswordStrength(strength);
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg">
                    <div className="authentication-box">
                        <div className="text-center">
                            <img width="200" src={logo} alt="" />
                        </div>
                        <div className="card mt-4 p-4">
                            {
                                resetSuccess?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-center">
                                                <img width="200" src={tickMark} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{textAlign:"center"}}>
                                            <h4>Your Password Has Been Reset</h4>
                                            <br/><br/>
                                            <h5>Please go back to your V-CX Application to log back in!</h5>
                                        </div>
                                    </div>
                                :
                                    <form className="theme-form">
                                        <h5 className="f-16 mb-3 f-w-600">{RESETPASSWORD}</h5>
                                        <div className="form-group">
                                            <label className="col-form-label">{NewPassword}</label>
                                            <input className="form-control password-eye"  type={showPassword? "text":"password"}  value={newPassword}
                                                    onChange={e => setNewPassword(e.target.value)} 
                                                    style={{fontSize:"12px"}}
                                                    onKeyUp={passwordKeyUpHandler}/>
                                            <div className="eye-password" onClick={e => setShowPassword(!showPassword)}>
                                                <FontAwesomeIcon icon={showPassword?faEyeSlash:faEye} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">{RetypePassword}</label>
                                            <input className="form-control password-eye" type={showConfirmPassword? "text":"password"} value={confirmPassword}
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                    style={{fontSize:"12px"}} />
                                            <div className="eye-password" 
                                                onClick={e => setShowConfirmPassword(!showConfirmPassword)}>
                                                <FontAwesomeIcon icon={showConfirmPassword?faEyeSlash:faEye} />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {/* <progress max="100" value="0" id="meter"></progress> */}
                                            <div className="progress"><div id="meter" className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" ></div></div>
                                            <h6 style={{color: "red"}} >{error} </h6>
                                        </div>
                                        <div className="form-group form-row mb-0">
                                            <div className="col-md-2">
                                                <button className="btn btn-primary" onClick={resetPassword}>{Reset}</button>
                                            </div>
                                        </div>
                                    </form>
                            }
                            
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(ResetAppPassword);