import React, { Suspense } from 'react';
import { Route, withRouter, Switch, Redirect, useLocation } from 'react-router-dom';

import {AnimatePresence} from 'framer-motion/dist/framer-motion'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AppSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './AppSettings')
);
const AccountSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './Account')
);
const CallControlSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './CallControl')
);
const CallGroupsSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './CallGroups')
);
const BlockedNumbersSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './BlockedNumbers')
);
const SchedulesSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './Schedules')
);
const VoicemailSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './Voicemail')
);
const BlfSettings = React.lazy(() =>
  import(/* webpackChunkName: "provisioning-dashboard" */ './Blfs')
);

const Settings = ({ match }) => {
    const location = useLocation()
    return (
        <AnimatePresence>
            <Suspense fallback={<div className="loading" />}>
                <Switch location={location} key={location.pathname}>

                    <Route
                        path={`${match.url}/appSettings`}
                        render={(props) => <AppSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/accountSettings`}
                        render={(props) => <AccountSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/callControlSettings`}
                        render={(props) => <CallControlSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/callGroupsSettings`}
                        render={(props) => <CallGroupsSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/blockedNumbersSettings`}
                        render={(props) => <BlockedNumbersSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/schedulesSettings`}
                        render={(props) => <SchedulesSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/voicemailSettings`}
                        render={(props) => <VoicemailSettings {...props} />}
                    />
                    <Route
                        path={`${match.url}/blfSettings`}
                        render={(props) => <BlfSettings {...props} />}
                    />

                    <Redirect to="/error" />
                </Switch>
                <ToastContainer />
            </Suspense>
        </AnimatePresence>
        
    )
};
export default Settings;
