import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { CSVLink, CSVDownload } from "react-csv";
import {Card,CardBody,CardHeader, Input, Toast } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';
import ReactFileReader from 'react-file-reader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faSync } from '@fortawesome/free-solid-svg-icons'


import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import QRModal from './qrModal'

import '../../assets/scss/app-styles/extensions.scss'
import { CollapsibleGroupItem, ThisWeekTask } from '../../constant';

class Extensions extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedTenant: "",
            allUsersCheckedList: "",
            usersAdded: "",
            usersRemoved: "",
            style: 'custom-spinner',
            cc: '',
            body: '',
            to: '',
            subject: '',
            linked: "false",
            qrModal: false,
            showTemplateSave: false,
            tenantList: [],
            usersList: [],
            allTabsCheckedList: "",
            globalUserList: [],
            tenantLevelMobileAppEnabled: false,
            tenantLevelDesktopAppEnabled: false,
            withProps: true
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    componentDidMount () {
        this.refreshTenantList(false)
    }
    refreshTenantList = (sync) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {}
        if(sync){
            payLoad["sync"] = true
        }
        axios.post(`${process.env.REACT_APP_API_URL}tenantList`, payLoad, this.headers)
        .then(response=>{
            const tenants = (response.data).sort(this.GetSortOrder("tname"));
            this.setState({tenants: tenants});
            this.populateTenants(tenants, this.state.withProps);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    populateTenants = (tenants, withProps) => {
        let items = [];
            let selectedPrevTenant = null
            tenants.map((row, index)=>{
                const value = {tenant: row.tname, tenantId: row.tid, desktopAppAllowed: row.desktop_app_allowed, mobileAppAllowed: row.mobile_app_allowed}
                if(this.props.location.state && this.props.location.state.tenant_id && row.tid === this.props.location.state.tenant_id){
                    selectedPrevTenant = value;
                }
                items.push(value);
                return null
            });

            if(withProps && this.props.location.state && this.props.location.state.tenant_id){
                this.setState({selectedPrevTenant: [selectedPrevTenant]});
                this.refreshUsers(this.props.location.state.tenant_id, false)

                this.setState({selectedTenant: this.props.location.state.tenant_id});
                this.setState({selectedTenantName: this.props.location.state.selectedTenantName});
                this.setState({tenantLevelMobileAppEnabled: this.props.location.state.tenantLevelMobileAppEnabled});
                this.setState({tenantLevelDesktopAppEnabled: this.props.location.state.tenantLevelDesktopAppEnabled});
            }
            
            this.setState({tenantList: items});
    }

    removeSelectedTenant = () => {
        window.history.replaceState(null, '')
        window.location.reload();
        // this.setState({withProps: false});
        // this.populateTenants(this.state.tenants, false);
        // this.setState({selectedTenant: ""});
        // this.setState({selectedPrevTenant: []});
    }

    userChangeHandler = (evt) => {
        let allUsersCheckedList = this.state.allUsersCheckedList;

        if(evt.target.getAttribute("data-name") === "off"){

            //add
            if(allUsersCheckedList === ""){
                
                allUsersCheckedList = "&" + evt.target.getAttribute("data-value") +"&"
                //setAllUsersCheckedList(evt.target.value)
            }
            else{
                allUsersCheckedList += ",&" + evt.target.getAttribute("data-value") +"&";
            }
        }
        else{
            //remove
            const list =  (allUsersCheckedList).split(",");
            allUsersCheckedList = "";
            for(let i=0; i<list.length; i++){

                // if found the value ignore
                if(list[i] !== "&" + evt.target.getAttribute("data-value") +"&"){

                    if(allUsersCheckedList === ""){
                
                        allUsersCheckedList = list[i];
                    }
                    else{
                        allUsersCheckedList += "," + list[i];
                    }
                }
                
            }
        }

        this.setState({allUsersCheckedList: allUsersCheckedList});
        if(this.state.filteredListOfUsers){
            this.populateUsers(this.state.filteredListOfUsers, allUsersCheckedList, this.state.globalCheckMark);
        } else {
            this.populateUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark);
        }

    }
    

    tenantChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedTenant: ""});
            this.setState({usersList: []});
            return;
        }
        this.setState({selectedTenant: result[0].tenantId.toString()});
        
        const tenantName =  (result[0].tenant).replaceAll(" ", "_")
        this.setState({selectedTenantName: tenantName});
        this.setState({tenantLevelMobileAppEnabled: result[0].mobileAppAllowed});
        this.setState({tenantLevelDesktopAppEnabled: result[0].desktopAppAllowed});
        
        this.refreshUsers(result[0].tenantId.toString(), false);
        this.getDomains(result[0].tenantId.toString(), false);
        //this.setState({selectedPrevTenant: [result[0]]});
    }

    refreshUsers = (tenantId, sync) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {
            tenant_id: tenantId
        }
        if(sync){
            payLoad["sync"] = true;
        }

        axios.post(`${process.env.REACT_APP_API_URL}getExtensions`, payLoad, this.headers)
        .then(response=>{
            //const allowedScopes = ["system user", "Office Manager", "Elite", "Professional", "Advanced", "Call Center Agent", "Site Manager", "Super User"]
            // let subscribersList = (response.data).filter((el) => {
            //     return el.scope !== "";
            // });
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            let subscribersList = (response.data).sort(this.GetSortOrder("extension"));
            //const subscribersList = response.data;
            this.setState({mySubList: subscribersList});
            this.setState({globalUserList: []});
            this.populateUsers(subscribersList, this.state.globalUserList)
            //this.checkTenantEngageEnabled(result[0].tenant)
            if(sync){
                toast.success("It will take about 1 to 2 mintues to sync all extensions. Please refresh the page after 2 minutes to see the updated list.");
            }
            this.setState({checkedAll: false});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    // saveHandler = () => {
    //     const allUsersList = this.state.allUsersCheckedList.replaceAll("&","");
    //     //const allTabsCheckedList = this.state.allTabsCheckedList.replaceAll("&","");
    //     this.setState({style: 'custom-spinner'});
    //     const payLoad = {
    //         cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
    //         tenant : this.state.selectedTenant,
    //         username: JSON.parse(localStorage.getItem('creds')).username,
    //         user_id: JSON.parse(localStorage.getItem('creds')).id,
    //         list: allUsersList,
    //         //tabsList: allTabsCheckedList,
    //     }
    //     axios.post(`${process.env.REACT_APP_API_URL}checkExtensions`, payLoad, this.headers)
    //     .then(response=>{

    //         toast.success("Successfully Saved!");
    //         this.setState({style: 'custom-spinner-hide'});
            
    //     })
    //     .catch(err=>{
    //         toast.error(err.message);
    //         this.setState({style: 'custom-spinner-hide'});
    //     });
        
    // }
    
    onChangeHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    linkHandler = (evt) => {
        if (!this.state.to || !this.state.did || !this.state.pin || !this.state.password || 
            (!this.state.domainExists && !this.state.domain)){
            toast.error("All fields are mandatory to link!")
            return
        }
        if (!this.state.selectedExtension){
            toast.error("Extension not selected properly")
            return
        }
        this.setState({style: 'custom-spinner'});
        const payLoad= {
            email: this.state.to,
            did: this.state.did,
            pin: this.state.pin,
            password: this.state.password,
            extension: this.state.selectedExtension,
            
        }

        if(!this.state.domainExists){
            payLoad["domain"] = this.state.domain
            payLoad["tenant_id"] = this.state.selectedTenant
        }

        axios.post(`${process.env.REACT_APP_API_URL}linkExtension`, payLoad, this.headers)
        .then(response=>{
            
            this.setState({style: 'custom-spinner-hide'});
            this.setState({linked: "true"});
            toast.success("User Successfully Linked!");
            
        })
        .catch(err=>{
            this.setState({style: 'custom-spinner-hide'});
            if(err.response){
                toast.error(err.response.data)
                return
            }
            toast.error(err.message);
            
        });

        
    }

    sendEmailHandler = (evt) => {

        const subscribersList = this.state.mySubList;

        if(!this.state.to || !this.state.subject || !this.state.body){
            toast.error("To, Subject and Body are mandatory fields to send an email!");
            return;
        }
        for(let i=0; i< (subscribersList).length; i++){

            let email = subscribersList[i].email.split(";")[0];
            let body =  this.state.body;
            if(email !== "" &&  (this.state.to).includes(email)){
                body = body.replace("FIRST_NAME_HERE", subscribersList[i].firstname);
                body = body.replace("LAST_NAME_HERE", subscribersList[i].lastname);
                if(this.state.password){
                    body = body.replace("PASSWORD_HERE", "Your temporary password is: "+this.state.password);
                } else {
                    body = body.replace("PASSWORD_HERE", "");
                }
                
                //email = "daniyal@saevolgo.ca"
                const link = `<a href="${process.env.REACT_APP_PANEL_URL}/getQR?username=${subscribersList[i].email}&cloud_id=${JSON.parse(localStorage.getItem("creds")).cloud_id}">Click Here</a>`
                body = body.replace("LINK_HERE", link);
                const payLoad = {
                    recepient: email,
                    cc: this.state.cc,
                    subject: this.state.subject,
                    body: body,
                    user_id: JSON.parse(localStorage.getItem('creds')).id,
                };
        
                axios.post(`${process.env.REACT_APP_API_URL}emailLink`, payLoad, this.headers)
                .then(response=>{
                    this.setState({qrModal: false});
                })
                .catch(err=>{
                    toast.error("Unable to send an email to "+ subscribersList[i].extension);
                });
            }
        }
    }

    toggleHandler = () => {
        this.setState({qrModal: !this.state.qrModal});
    };

    qrHandler = async (event) => {
        if (event.target.getAttribute("data-email") === ""){
            toast.error("You cannot send QR link to this user as it has no email. Please add it from other portal")
            return
        }
        this.setState({cc: ''});
        this.setState({body: ''});
        this.setState({subject: ''});
        this.setState({did: ''});
        this.setState({pin: ''});
        this.setState({password: ''});
        this.setState({template_name: ''});
        this.setState({showTemplateSave: false});
        this.setState({to: event.target.getAttribute("data-email")});
        if(event.target.getAttribute("data-domain")){
            toast.info("Domain exists");
            this.setState({domainExists: true});
        } else {
            this.setState({domainExists: false});
        }
        this.setState({linked: event.target.getAttribute("data-linked")});
        this.setState({selectedExtension: event.target.getAttribute("data-extension")});
        await this.getEmailTemplates();
        this.setState({qrModal: true});
    }

    qrCodeHandler = async (event) => {
        //csc:username:password@CODE
        this.setState({cc: ''});
        this.setState({body: ''});
        this.setState({subject: ''});
        this.setState({template_name: ''});
        this.setState({showTemplateSave: false});

        let emailList = this.state.globalEmailList;

        if (emailList === ""){
            toast.error('No user with email selected! Please select users first, from checkboxes at the left.');
            return;
        }
        this.setState({to: emailList});
        await this.getEmailTemplates();
        this.setState({qrModal: true});
    }

    templateSelectedHandler = (evt) => {

        const data = this.state.templatesData;
        this.setState({body: data[evt.target.getAttribute("data-id")]["body"]});
        this.setState({template_name: data[evt.target.getAttribute("data-id")]["name"]});
        this.setState({subject: data[evt.target.getAttribute("data-id")]["subject"]});
        this.setState({showTemplateSave: true});
        this.setState({templateSelected: true});
    }

    deleteTemplate = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to delete email template");
            return
        }

        confirmAlert({
            title: 'Are you sure you want to delete this email template?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id: evt.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        }

                        axios.post(`${process.env.REACT_APP_API_URL}deleteTemplate`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Successfully Deleted Template");
                            this.getEmailTemplates();
                            this.setState({style: 'custom-spinner-hide'});
                            this.setState({body: ''});
                            this.setState({subject: ''});
                            this.setState({template_name: ''});
                            this.setState({showTemplateSave: false});
                            this.setState({templateSelected: false});
                        })
                        .catch(err=>{
                            toast.error("Failed to delete email template");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    getEmailTemplates = () => {
        this.setState({style: 'custom-spinner'});
        axios.get(`${process.env.REACT_APP_API_URL}getTemplates`, this.headers)
        .then(response=>{
            const data = response.data;
            let items = [];
            let templatesData = {};
            data.map((row, index)=>{
                templatesData[row.id] = {
                    body: row.body,
                    name: row.name,
                    subject: row.subject
                }
                return (items.push(
                    <div key={"templates"+index} className="template-container">
                        <label className="name-label" onClick={this.templateSelectedHandler} data-id={row.id}>{row.name}</label>
                        <div onClick={this.deleteTemplate} data-id={row.id}> <i data-id={row.id} className="fa fa-times-circle"></i> </div>
                    </div>)
            )});
            this.setState({templatesData: templatesData});
            this.setState({templatesList: items});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            this.setState({templatesData: null});
            this.setState({templatesList: []});
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    editorOnChangeHandler = (evt) => {
        this.setState({body: evt.editor.getData()})
    }

    toggleTemplateSave = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to add email template");
            return
        }
        if(!this.state.showTemplateSave){
            this.setState({body: ''});
            this.setState({subject: ''});
            this.setState({template_name: ''});
            this.setState({templateSelected: false});
        }
        this.setState({showTemplateSave: !this.state.showTemplateSave})
    }

    saveTemplateHandler = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to update email template");
            return
        }
        this.setState({style: 'custom-spinner'});
        if(!this.state.template_name || !this.state.body){
            toast.error("Template name and body are mandatory");
            this.setState({style: 'custom-spinner-hide'});
            return;
        }
        const payLoad = {
            name: this.state.template_name,
            body: this.state.body,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }

        if(this.state.subject !== ''){
            payLoad["subject"] = this.state.subject;
        }

        axios.post(`${process.env.REACT_APP_API_URL}templateCreateUpdate`, payLoad, this.headers)
        .then(response=>{
            toast.success("Successfully saved email template");
            this.setState({templateSelected: true});
            this.getEmailTemplates();
        })
        .catch(err=>{
            toast.error("Failed to save email template");
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    autoGeneratePassword = (evt) => {
        let length = 12,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!)(",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        this.setState({password: retVal});
        toast.warn("Please remember this password!");
    }

    desktopAppChangeHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {
            id: evt.target.getAttribute("data-id"),
            tenant_id: this.state.selectedTenant,
            column_name: "desktop_app_allowed",
        }
        if(evt.target.getAttribute("data-name") === "off"){
            //enable
            payLoad["value"] = "true"
        } else {
            // disable
            payLoad["value"] = "false"
        }

        axios.post(`${process.env.REACT_APP_API_URL}updateAndGetExtension`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            let subscribersList = (response.data).sort(this.GetSortOrder("extension"));
            this.populateUsers(subscribersList, this.state.globalUserList)
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    
    }

    mobileAppChangeHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {
            id: evt.target.getAttribute("data-id"),
            tenant_id: this.state.selectedTenant,
            column_name: "mobile_app_allowed",
        }
        if(evt.target.getAttribute("data-name") === "off"){
            //enable
            payLoad["value"] = "true"
        } else {
            // disable
            payLoad["value"] = "false"
        }

        axios.post(`${process.env.REACT_APP_API_URL}updateAndGetExtension`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            let subscribersList = (response.data).sort(this.GetSortOrder("extension"));
            this.populateUsers(subscribersList, this.state.globalUserList)
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    
    }


    tenantLevelDesktopAppChangeHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {
            tenant_id: this.state.selectedTenant,
            column_name: "desktop_app_allowed",
        }
        if(evt.target.getAttribute("data-action") === "add"){
            //enable
            payLoad["value"] = "true"
            this.setState({tenantLevelDesktopAppEnabled: true});
        } else {
            // disable
            payLoad["value"] = "false"
            this.setState({tenantLevelDesktopAppEnabled: false});
        }

        axios.post(`${process.env.REACT_APP_API_URL}updateAndGetTenants`, payLoad, this.headers)
        .then(response=>{
            const tenants = (response.data).sort(this.GetSortOrder("tname"));
            let items = [];
            tenants.map((row, index)=>(
                items.push({tenant: row.tname, tenantId: row.tid, desktopAppAllowed: row.desktop_app_allowed, mobileAppAllowed: row.mobile_app_allowed})
            ));

            this.setState({tenantList: items});
            this.populateUsers(this.state.mySubList, this.state.globalUserList);
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    
    }

    tenantLevelMobileAppChangeHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {
            tenant_id: this.state.selectedTenant,
            column_name: "mobile_app_allowed",
        }
        if(evt.target.getAttribute("data-action") === "add"){
            //enable
            payLoad["value"] = "true"
            this.setState({tenantLevelMobileAppEnabled: true});
        } else {
            // disable
            payLoad["value"] = "false"
            this.setState({tenantLevelMobileAppEnabled: false});
        }

        axios.post(`${process.env.REACT_APP_API_URL}updateAndGetTenants`, payLoad, this.headers)
        .then(response=>{
            const tenants = (response.data).sort(this.GetSortOrder("tname"));
            let items = [];
            tenants.map((row, index)=>(
                items.push({tenant: row.tname, tenantId: row.tid, desktopAppAllowed: row.desktop_app_allowed, mobileAppAllowed: row.mobile_app_allowed})
            ));

            this.setState({tenantList: items});
            this.populateUsers(this.state.mySubList, this.state.globalUserList);
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    
    }

    editHandler = (evt) => {
        this.setState({extensionSelected: evt.target.getAttribute("data-extension")});
        this.setState({isLinked: evt.target.getAttribute("data-linked")});
    }

    checkIfEveryElementExisits = (data, globalCheckMark) => {
        // for (let i=0; i< data.length; i++){
        //     if (!globalCheckMark.includes(JSON.stringify(data[i]))){
        //         return false;
        //     }
        // }
        return data.length === globalCheckMark.length;
    }

    multiSelect = (event) => {

        if(event.target.checked) {
            const userList =  this.state.globalUserList;
            const subList = this.state.mySubList;
            for(let i=0; i< subList.length; i++){
                if(!this.checkIfUserExists(userList, subList[i])){
                    userList.push(subList[i]);
                }
            }
            this.setState({globalUserList: userList});
            this.populateUsers(this.state.mySubList, userList);
        } else {
            this.setState({globalUserList: []});
            this.populateUsers(this.state.mySubList, []);
        }
        
    }

    checkIfUserExists = (userList, value) => {
        for(let i=0; i<userList.length; i++){
            if(userList[i].extension === value.extension){
                return true;
            }
        }

        return false;
    }

    singleSelect = (event) => {
        const value = JSON.parse(event.target.value);
        let userList =  this.state.globalUserList;

        if(event.target.checked){
            if(!this.checkIfUserExists(userList, value)){
                userList.push(value);
            }
        } else {
            userList = userList.filter(function(el) { return el.extension !== value.extension }); 
        }
        this.setState({globalUserList: userList});
        this.populateUsers(this.state.mySubList, userList);

    }
    populateUsers = (data, globalUserList) => {
        let columns =[
            {
                label: <div  className="checkbox checkbox-primary" >
                <input id={"checkbox-primary-head1"} type="checkbox"
                    onChange={this.multiSelect} value = {JSON.stringify(data)}
                    checked={this.checkIfEveryElementExisits(data, globalUserList)}/>
                    <label className="checkbox-primary-head1" htmlFor={"checkbox-primary-head1"}></label>
                </div>,
                field: 'checkbox',
                sort: 'disabled',
            },
            {
                label: 'Extension',
                field: 'extension',
            },
            {
                label: 'Domain',
                field: 'domain',
            },
            {
                label: 'Site',
                field: 'site_name',
            },
            {
                label: 'First Name',
                field: 'firstname',
            },
            {
                label: 'Last Name',
                field: 'lastname',
            },
            {
                label: 'Admin',
                field: 'admin',
            },
            {
                label: 'Email',
                field: 'email',
            },
            {
                label: 'Linked',
                field: 'linked',
            },
            {
                label: 'Mobile App',
                field: 'mobile_app_allowed',
            },
            {
                label: 'Desktop App',
                field: 'desktop_app_allowed',
            },
            {
                label: 'Send Link',
                field: 'qr',
                sort: 'disabled',
            },
            {
                label: 'Edit',
                field: 'edit',
                sort: 'disabled',
            },
        ];

        if(this.state.tenantLevelMobileAppEnabled){
            columns = columns.filter(function(el) { return el.label !== "Mobile App" }); 
        }
        if(this.state.tenantLevelDesktopAppEnabled){
            columns = columns.filter(function(el) { return el.label !== "Desktop App" }); 
        }

        let myLinked = false;
        let dataModified = null;
        if(data != null)
        {
            const col_data = [['Extension','Domain','Site Name','First Name', 'Last Name','Admin','Email','Linked', 'Mobile App Allowed', 'Desktop App Allowed']];
            dataModified = data.map((row, index)=>{
                col_data.push([row.extension.toString(), row.domain, row.site_name ,row.firstname,row.lastname, row.admin.toString(), row.email,row.linked.toString(), row.mobile_app_allowed.toString(), row.desktop_app_allowed.toString()])
                myLinked = row.linked;
                return ({...row, 
                    checkbox: <div  className="checkbox checkbox-primary" >
                    <input id={"checkbox-table-"+index} type="checkbox" value={JSON.stringify(row)}
                        onChange={this.singleSelect} checked={this.checkIfUserExists(globalUserList, row)}/>
                        <label className="custom-check-td" htmlFor={"checkbox-table-"+index}></label>
                    </div>,
                    qr: <i className="fas fa-qrcode data-table-icon mg-20"
                    onClick={this.qrHandler} data-extension={row.extension} 
                        data-email={row.email} data-linked={myLinked} data-domain={row.domain}></i>,
                    
                    edit:<i className="far fa-edit data-table-icon mg-20"
                    onClick={this.editHandler} data-extension={row.extension} data-linked={myLinked}></i>,
                    domain : row.domain,
                    mobile_app_allowed :<div> 
                        {
                            row.mobile_app_allowed?
                                <i className="fas fa-toggle-on data-table-icon mg-20" 
                                    onClick={this.mobileAppChangeHandler} 
                                    title="Disable Mobile App Provisioning" 
                                    data-id={row.id} style={{color: "green"}}
                                    data-name="on"/>
                            :
                                <i className="fas fa-toggle-off data-table-icon mg-20" 
                                    title="Enable Mobile App Provisioning"
                                    onClick={this.mobileAppChangeHandler}  
                                    data-id={row.id}
                                    data-name="off"/>

                        }
                    </div>, 
                
                    desktop_app_allowed :<div> 
                    {
                        row.desktop_app_allowed?
                            <i className="fas fa-toggle-on data-table-icon mg-20" 
                                onClick={this.desktopAppChangeHandler} 
                                title="Disable Desktop App Provisioning" 
                                data-id={row.id} style={{color: "green"}}
                                data-name="on"/>
                        :
                            <i className="fas fa-toggle-off data-table-icon mg-20" 
                                title="Enable Desktop App Provisioning"
                                onClick={this.desktopAppChangeHandler}  
                                data-id={row.id}
                                data-name="off"/>

                    }
                    </div>, 
                });
            });

            dataModified = dataModified.map((row) => {
                myLinked = row.linked;
                if(row.admin){
                    row.admin = "Yes"
                }else {
                    row.admin = "No"
                }

                if (row.linked){
                    row.linked = <FontAwesomeIcon 
                    icon={faCheckCircle} className="custom-data-table-icon mg-20"
                    style={{color: "green", fontSize: "20px"}} />
                } else {
                    row.linked = <FontAwesomeIcon 
                    icon={faTimesCircle} className="custom-data-table-icon mg-20"
                    style={{color: "red", fontSize: "20px"}}/>
                }

                return({...row});
            });
            this.setState({csvData: col_data});
        }
        
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({style: 'custom-spinner-hide'});
    }

    checkEmailHandler = (evt) => {
        this.setState({goToCheckEmailPage: true});
    }

    relinkHandler = () => {
        const usersList = this.state.globalUserList;
        if(usersList.length === 0){
            toast.error("Please select the users first!")
            return;
        }
        let payLoad = [];
        for(let i=0; i<usersList.length; i++){
            const email = usersList[i].email;
            if(email.length === 0){
                toast.error("One of the user selected has no email attached. Please choose users who have valid emails!")
                return;
            }
            payLoad.push({
                tenant_id: this.state.selectedTenant,
                email: email,
                extension: usersList[i].extension
            })
        }
        axios.post(`${process.env.REACT_APP_API_URL}reLinkExtensions`, payLoad, this.headers)
        .then(response=>{
            toast.info("It will take about "+ 4 * usersList.length + " seconds to complete relinking process. Please revisit the page to see which users are linked after some time.")
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
        
    }

    handleImport = (files) => {
        var reader = new FileReader();
        const scope = this;
        reader.onload = function (e) {
            scope.setState({style: 'custom-spinner'});
            // Use reader.result
            const csv = reader.result;
            let lines = csv.split('\n');
            for (let i = 0; i < lines.length; i++){
                lines[i] = lines[i].replaceAll(`"`,``)
            }
            const payLoad = [];
            const headers = ['Extension','Domain','Site Name','First Name', 'Last Name','Admin','Email','Linked', 'Mobile App Allowed', 'Desktop App Allowed'];
            const keys = ['extension','domain', 'site_name', 'firstname', 'lastname','admin','email','linked', 'mobile_app_allowed', 'desktop_app_allowed'];
            let key = null;
            let value = null;
            if(!lines[0].includes("Extension,Domain,Site Name,First Name,Last Name,Admin,Email,Linked,Mobile App Allowed,Desktop App Allowed")){
                toast.error("Invalid Format");
                scope.setState({ csvData: null, CSVProcessed: false});
                scope.setState({style: 'custom-spinner-hide'});
                return;
            }
            for (let i = 1; i < lines.length; i++) {
                if (!lines[i])
                    continue;

                const obj = {};
                const currentline = lines[i].split(',');
                for (let j = 0; j < headers.length; j++) {
                    key = keys[j];
                    value = currentline[j].replace('\r', '');
                    // now have to send all these values to backend and update them based upon
                    obj[key] = value;
                }
                obj["tenantid"] = scope.state.selectedTenant;
                payLoad.push(obj);
            }
            axios.post(`${process.env.REACT_APP_API_URL}extensionsImport`, payLoad, scope.headers)
            .then(response=>{
                toast.info("It will take about "+ 3 * payLoad.length + " seconds to complete import process. Please revisit the page after some time to see changes.")
                scope.setState({style: 'custom-spinner-hide'});
            })
            .catch(err=>{
                toast.error(err.message);
                scope.setState({style: 'custom-spinner-hide'});
            });
        };
        reader.readAsText(files[0]);
    };

    getDomains = (tenantId, sync) => {
        const payLoad = {
            tenant_id: tenantId
        };
        if(sync){
            payLoad["sync"] = true;
        }
        axios.post(`${process.env.REACT_APP_API_URL}getRemoteDomains`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            response.data.map((row)=>(
                items.push(<option value={row.domain}>{row.domain + " - " + row.site_name}</option>)
            ));
            this.setState({domainItems: items});
        }).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    syncHandler = () => {
        this.getDomains(this.state.selectedTenant, true);
    }


    render () {

        let redirect = null;
        if(this.state.extensionSelected){
            this.setState({extensionSelected: false});
            redirect = <Redirect to = {{
                pathname: "/tenants/details",
                state: { extension: this.state.extensionSelected, tenant_id: this.state.selectedTenant,
                    isLinked: this.state.isLinked, tenantLevelMobileAppEnabled: this.state.tenantLevelMobileAppEnabled,
                    tenantLevelDesktopAppEnabled: this.state.tenantLevelDesktopAppEnabled,
                    selectedTenantName: this.state.selectedTenantName}
            }} />;
        }
        if(this.state.goToCheckEmailPage){
            this.setState({goToCheckEmailPage: false});
            redirect = <Redirect to = {{
                    pathname: "/tenants/check_email",
                    state: {id:"1"}
            }} />;
        }
        return (
            <Fragment>
                <Breadcrumb parent="App" title="Extension Management" />
                {redirect}
                <QRModal
                    show={this.state.qrModal}
                    toggleHandler={this.toggleHandler}
                    onChangeHandler = {this.onChangeHandler}
                    sendEmailHandler = {this.sendEmailHandler}
                    linkHandler = {this.linkHandler}
                    editorOnChangeHandler={this.editorOnChangeHandler}
                    cc = {this.state.cc}
                    body= {this.state.body}
                    to = {this.state.to}
                    subject= {this.state.subject}
                    pin= {this.state.pin}
                    did= {this.state.did}
                    domain= {this.state.domain}
                    domainExists = {this.state.domainExists}
                    password= {this.state.password}
                    autoGeneratePassword= {this.autoGeneratePassword}
                    templatesList = {this.state.templatesList}
                    showTemplateSave={this.state.showTemplateSave}
                    template_name={this.state.template_name}
                    toggleTemplateSave={this.toggleTemplateSave}
                    templateSelected={this.state.templateSelected}
                    linked={this.state.linked}
                    saveTemplateHandler={this.saveTemplateHandler}
                    domainItems={this.state.domainItems}
                    syncHandler={this.syncHandler}/>

                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Choose Tenant</h5>
                            <button className="btn btn-warning check-email-btn"
                                type="button" onClick={this.checkEmailHandler}>Check Email</button>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="theme-form">
                                    <div className="form-group">
                                        <i className="fa fa-search type-ahead-search-icon"/>
                                        <Typeahead
                                            id="basic-typeahead"
                                            className="search-domains"
                                            labelKey="tenant"
                                            multiple={false}
                                            selected={this.state.selectedPrevTenant}
                                            options={this.state.tenantList}
                                            onChange={this.tenantChangeHandler}
                                            placeholder="Choose a tenant..."
                                        />
                                        {
                                            this.props.location.state && this.props.location.state.tenant_id?
                                            <FontAwesomeIcon 
                                                icon={faTimesCircle} className="custom-data-table-icon tenant-cross"
                                                title="Remove"
                                                onClick={this.removeSelectedTenant}/>
                                            :null
                                            
                                        }
                                        <FontAwesomeIcon 
                                        icon={faSync} className="custom-data-table-icon tenant-refresh"
                                        title="Sync Tenants List"
                                        onClick={e => this.refreshTenantList(true)}/>
                                    </div>
                                    
                                </form>
                            </div>
                            { this.state.selectedTenant !== "" && this.state.dataTable?
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 domain-level-toggles"> 
                                        <label style={{marginRight: "10px"}}>Tenant Level Mobile App</label> <br/>
                                        {
                                            this.state.tenantLevelMobileAppEnabled?
                                                <i className="fas fa-toggle-on data-table-icon" 
                                                    onClick={this.tenantLevelMobileAppChangeHandler} 
                                                    title="Disable Tenant Level Mobile App"
                                                    style={{color: "#6F90A7"}}
                                                    data-action="remove"/>
                                            :
                                                <i className="fas fa-toggle-off data-table-icon" 
                                                    title="Enable Tenant Level Mobile App"
                                                    onClick={this.tenantLevelMobileAppChangeHandler}
                                                    data-action="add"/>

                                        }
                        
                                    </div>

                                    <div className="col-md-6 col-sm-12 domain-level-toggles"> 
                                        <label style={{marginRight: "10px"}}>Tenant Level Desktop App</label><br/>
                                        {
                                            this.state.tenantLevelDesktopAppEnabled?
                                                <i className="fas fa-toggle-on data-table-icon" 
                                                    onClick={this.tenantLevelDesktopAppChangeHandler} 
                                                    title="Disable Tenant Level Desktop App"
                                                    style={{color: "#6F90A7"}}
                                                    data-action="remove"/>
                                            :
                                                <i className="fas fa-toggle-off data-table-icon" 
                                                    title="Enable Tenant Level Desktop App"
                                                    onClick={this.tenantLevelDesktopAppChangeHandler}
                                                    data-action="add"/>

                                        }
                        
                                    </div>

                                </div>
                                
                                : null
                            }
                        </CardBody>
                    </Card>
                    <div className="col-md-1 col-sm-0"></div>
                </div>
                {
                    this.state.selectedTenant !== "" && this.state.dataTable?

                        <div className="container-fluid main-container">
                        
                            <Card className="col-md-12 col-sm-12">
                                <CardHeader>
                                    <h5>Subscribers</h5>
                                    <button className="btn btn-danger check-email-btn"
                                        type="button" 
                                        title="Sync Subscribers List"
                                        onClick={e => this.refreshUsers(this.state.selectedTenant, true)}>
                                        Sync
                                    </button>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div className="row" style={{float:"right"}}>
                                        <button onClick={this.relinkHandler} type="button"  className="btn btn-rounded-custom btn-primary mg-20" >Relink</button>
                                        <ReactFileReader handleFiles={this.handleImport} fileTypes={'.csv'}>
                                            <button type="button"  className="btn btn-rounded-custom btn-info table-btn mg-20" >Import</button>
                                        </ReactFileReader>
                                        {/* <button onClick={this.relinkHandler} type="button"  className="btn btn-rounded-custom btn-secondary table-btn " >Export</button> */}
                                        <CSVLink className="btn btn-rounded-custom btn-success csv mg-20" data={this.state.csvData} filename={"Extensions_"+this.state.selectedTenantName+".csv"}>Export</CSVLink>
                                    </div>
                                    <MDBDataTableV5 hover entriesOptions={[10, 25, 50, 75, 100, 150, 200]} 
                                        entries={50}
                                        pagesAmount={7} data={this.state.dataTable} 
                                        searchTop  searchBottom={false}/>
                                    
                                    {/* <div className="btn-container">
                                        <button className="btn btn-primary" type="button" onClick={this.saveHandler}>Save</button>
                                        <button className="btn btn-secondary" type="button" onClick={this.qrCodeHandler} style={{marginLeft: "15px"}}>
                                            Send QR Code
                                        </button>
                                    </div> */}
                                </CardBody>
                                
                            </Card>
                                
                        
                        </div>
                    :
                    null
                }
            </Fragment>
        );
    }
    
};

export default Extensions;