import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'
import Auxi from '../../hoc/Auxi'


class Resellers extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            userIdSelected: null,
            style: 'custom-spinner',
            loading: true,
            tenantList: [],
            selectedTenant: "",
		    backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
        if (JSON.parse(localStorage.getItem('creds')).user_type !== "admin") {
            this.setState({backToIndex: true});
            return
        }
        if(this.props.location.state.id){
            this.setState({id: this.props.location.state.id});
            this.setState({name: this.props.location.state.name});
            this.refreshResellers(this.props.location.state.id)
        }
        this.refreshTenantList(false)
    }
    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    refreshTenantList = (sync) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {}
        axios.post(`${process.env.REACT_APP_API_URL}tenantList`, payLoad, this.headers)
        .then(response=>{
            const tenants = (response.data).sort(this.GetSortOrder("tname"));
            this.setState({tenants: tenants});
            this.populateTenants(tenants, this.state.withProps);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    saveHandler = (event)=> {
		event.preventDefault();
		if (!this.state.name ) {
            toast.error('Name is mandatory to save reseeler!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		let payLoad = {
            name : this.state.name
        }
        if(this.state.id){
            payLoad["id"] = this.state.id
        }
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateReseller`, payLoad, this.headers)
		.then(response => {
            this.setState({style: 'custom-spinner-hide'});
            toast.success("Successfully Updated Reseller Information!")
            this.refreshResellers(response.data.id)
            this.setState({id: response.data.id})
		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

    populateTenants = (tenants, withProps) => {
        let items = [];
            tenants.map((row, index)=>{
                const value = {tenant: row.tname, tenantId: row.tid, desktopAppAllowed: row.desktop_app_allowed, mobileAppAllowed: row.mobile_app_allowed}
               
                items.push(value);
                return null
            });

            if(withProps && this.props.location.state && this.props.location.state.tenant_id){
                this.refreshUsers(this.props.location.state.tenant_id, false)

                this.setState({selectedTenant: this.props.location.state.tenant_id});
                this.setState({selectedTenantName: this.props.location.state.selectedTenantName});
                this.setState({tenantLevelMobileAppEnabled: this.props.location.state.tenantLevelMobileAppEnabled});
                this.setState({tenantLevelDesktopAppEnabled: this.props.location.state.tenantLevelDesktopAppEnabled});
            }
            
            this.setState({tenantList: items});
    }

    refreshResellers = (resellerID) => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            reseller_id: resellerID
        };

        axios.post(`${process.env.REACT_APP_API_URL}getResellerTenants`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    childTenantChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedChildTenant: ""});
            return;
        }
        this.setState({selectedChildTenant: result[0].tenantId.toString()});
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Tenant Id',
                field: 'tenant_id',
            },
            {
                label: 'Tenant Name',
                field: 'tname',
            },
            
            {
                label: 'Delete',
                field: 'delete',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataModified = dataFromApi.map((row)=>{
                return ({...row, delete:  <div className="edit-column">
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.deleteHandler} data-id={row.id}></i>
                </div>});
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    deleteHandler = (event) =>{

        confirmAlert({
            title: 'Are you sure you want to remove this tenant?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteResellerTenant`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshResellers(this.state.id)
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    editHandler = (event) =>{
        this.setState({userIdSelected: event.target.getAttribute("data-id")})
        // will call the details compnent but with data id selected here as a prop

    }

    addNewHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        if (!this.state.selectedChildTenant){
            toast.error("Please select the reseller and tennat to supervise first! ")
            this.setState({style: 'custom-spinner-hide'});
            return
        }
        const payLoad = {
            reseller_id: this.state.id,
            tenant_id: this.state.selectedChildTenant
        };

        axios.post(`${process.env.REACT_APP_API_URL}createUpdateResllerTenants`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            this.refreshResellers(this.state.id)
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

    render() {

        let redirect = null;

        if(this.state.backToIndex){
            redirect = <Redirect to = {{
                    pathname: "/dashboard"
            }} />;
        }

        return(
            <Fragment>
                <Breadcrumb parent="Resellers" title="Details" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>Reseller</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="theme-form">
                                    <div className="row form-group">
                                        <div className="col-md-10">
                                            <label className="float-left">
                                                    Name
                                            </label> <span> * </span>
                                            <input type="text" className="form-control" name="phone_number"
                                                onChange={e => this.setState({name: e.target.value})} value={this.state.name}/>
                                        </div>
                                        <div className="col-md-1">
                                            <button type="button" onClick={this.saveHandler} 
                                                className="button-custom btn shadow-2 btn-success btn-width btn-reseller-save">
                                                {this.state.id? "UPDATE": "SAVE"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </CardBody>
                    </Card>

                    
                    <div className="col-md-2 col-sm-0"></div>
                </div>
                {
                    this.state.id? 
                    <Auxi>
                        <div className="container-fluid main-container">
                            <div className="col-md-2 col-sm-0"></div>
                            <Card className="col-md-8 col-sm-12">
                                <CardHeader>
                                    <h5>Tenant to Supervise</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div id="the-basics">
                                        <form className="theme-form" style={{width: "calc(100% - 166px)"}}>
                                            <div className="form-group">
                                                <i className="fa fa-search type-ahead-search-icon"/>
                                                <Typeahead
                                                    id="basic-typeahead"
                                                    className="search-domains"
                                                    labelKey="tenant"
                                                    multiple={false}
                                                    options={this.state.tenantList}
                                                    onChange={this.childTenantChangeHandler}
                                                    placeholder="Choose a tenant..."
                                                />
                                            </div>
                                            
                                        </form>
                                        <button className="btn btn-primary btn-reseller-add"
                                        type="button" onClick={this.addNewHandler}>Add</button>
                                    </div>
                                </CardBody>
                            </Card>

                            
                            <div className="col-md-2 col-sm-0"></div>
                        </div>
                        <div className="container-fluid main-container">
                            <div className="col-md-1 col-sm-0"></div>
                            <Card className="col-md-10 col-sm-12">
                                <CardHeader>
                                    <h5>Tenants Under this Reseller</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div className = "MainIndex">
                                        <div className="users-table">
                                        <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                                        pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            
                            <div className="col-md-1 col-sm-0"></div>
                        </div>

                        
                    </Auxi>
                : null

                }
            
            </Fragment>

        );

    }

}

export default Resellers;
