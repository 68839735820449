import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import { Typeahead } from 'react-bootstrap-typeahead';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class CheckEmail extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            style: 'custom-spinner-hide',
            loading: true,
            tenantList: [],
		    backToExtensions: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    componentDidMount(){
        this.setState({style: 'custom-spinner'});
        let payLoad = {}
        axios.post(`${process.env.REACT_APP_API_URL}tenantList`, payLoad, this.headers)
        .then(response=>{
            const tenants = (response.data).sort(this.GetSortOrder("tname"));
            let items = [];
            tenants.map((row, index)=>{
                const value = {tenant: row.tname, tenantId: row.tid, desktopAppAllowed: row.desktop_app_allowed, mobileAppAllowed: row.mobile_app_allowed}
                items.push(value)
                return null
            });

            this.setState({tenantList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    searchData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            email: this.state.email,
        };
        if(this.state.selectedTenant){
            payLoad["tenant_id"] = this.state.selectedTenant;
        }
        axios.post(`${process.env.REACT_APP_API_URL}checkDuplicateEmails`, payLoad, this.headers)
        .then(response=>{
            
            if(response.data.length === 0){
                toast.error("No Record Found");
            }
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Extension',
                field: 'extension',
            },
            {
                label: 'First Name',
                field: 'firstName',
            },
            {
                label: 'Last Name',
                field: 'lastName',
            },
            {
                label: 'Admin',
                field: 'admin',
            },
            {
                label: 'Email',
                field: 'email',
            },
            {
                label: 'Linked',
                field: 'linked',
            },
        ];
        if(!this.state.selectedTenant){
            columns.push({
                label: 'Tenant',
                field: 'tenant',
            });
        }
        let myLinked = false;
        let dataModified = dataFromApi.map((row) => {
            myLinked = row.linked;
            if(row.admin){
                row.admin = "Yes"
            }else {
                row.admin = "No"
            }

            if (row.linked){
                row.linked = <FontAwesomeIcon 
                icon={faCheckCircle} className="custom-data-table-icon"
                style={{color: "green", fontSize: "20px"}} />
            } else {
                row.linked = <FontAwesomeIcon 
                icon={faTimesCircle} className="custom-data-table-icon"
                style={{color: "red", fontSize: "20px"}}/>
            }

            return({...row});
        });

        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    tenantChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedTenant: ""});
            this.setState({usersList: []});
            return;
        }
        this.setState({selectedTenant: result[0].tenantId.toString()});
    }

    

    render() {

        let redirect = null;
        if(this.state.backToExtensions){
            redirect = <Redirect to = {{
                    pathname: "/tenants"
            }} />;
        }

        return(
            <Fragment>
                <Breadcrumb parent="App Management" title="Check Email" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Choose Tenant</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="theme-form">
                                    <div className="form-group">
                                        <i className="fa fa-search type-ahead-search-icon"/>
                                        <Typeahead
                                            id="basic-tenant-typeahead"
                                            className="search-domains"
                                            labelKey="tenant"
                                            multiple={false}
                                            options={this.state.tenantList}
                                            onChange={this.tenantChangeHandler}
                                            placeholder="Choose a tenant..."
                                        />
                                    </div>
                                    
                                </form>
                            </div>

                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8 search-container">
                                    <div className="row">
                                        <div className="col-md-8 email-div">
                                            <input type="text" className="form-control"
                                                placeholder="Enter email here"
                                                onChange={e => this.setState({email: e.target.value})} 
                                                value={this.state.email}/>
                                        </div>

                                        <div className="col-md-4"> 
                                            <button onClick={this.searchData} type="button"  
                                            className="btn btn-rounded-custom btn-primary table-btn" >Search </button>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-md-2"></div>
                                
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-1 col-sm-0"></div>
                </div>

                

                <div className = "MainIndex">
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

                <div className="row main-container">
                    <div className="col-md-12" style={{textAlign:"center"}}>

                        <button type="button" onClick={evt => this.setState({backToExtensions: true})} 
                            className="button-custom btn shadow-2 btn-secondary btn-width">
                            CANCEL
                        </button>

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default CheckEmail;
