import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class Summary extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            userIdSelected: null,
            style: 'custom-spinner',
            loading: true,
		    backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
        if (JSON.parse(localStorage.getItem('creds')).user_type !== "admin") {
            this.setState({backToIndex: true});
            return
        }
        this.refreshData();
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}resellerSummary`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Name',
                field: 'name',
            },
            {
                label: 'Edit - Delete',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                return({...row});
            });
            dataModified = dataFromApi.map((row)=>{
                return ({...row, edit:  <div className="edit-column">
                <i className="far fa-edit data-table-icon"
                    onClick={this.editHandler} data-id={row.id} data-name={row.name}></i> -
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.deleteHandler} data-id={row.id}></i>
                </div>});
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    deleteHandler = (event) =>{

        confirmAlert({
            title: 'Are you sure you want to delete this reseller?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteReseller`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    editHandler = (event) =>{
        this.setState({idSelected: event.target.getAttribute("data-id")})
        this.setState({nameSelected: event.target.getAttribute("data-name")})
        // will call the details compnent but with data id selected here as a prop

    }

    addNewHandler = (evt) => {
        evt.preventDefault();

        this.setState({newRecordClicked: true});

    }

    render() {

        let redirect = null;
        if(this.state.newRecordClicked){
            this.setState({newRecordClicked: false});
            redirect = <Redirect to = {{
                pathname: "/resellers/details",
                state: { id: null}
            }} />;

        }
        
        if(this.state.idSelected){
            this.setState({idSelected: null});
            redirect = <Redirect to = {{
                pathname: "/resellers/details",
                state: { id: this.state.idSelected, name: this.state.nameSelected}
            }} />;
        }

            if(this.state.backToIndex){
                redirect = <Redirect to = {{
                        pathname: "/dashboard"
                }} />;
            }

        return(
            <Fragment>
                <Breadcrumb parent="App" title="Resellers" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className = "MainIndex">
                    <div className="row" style={{float:"right"}}>
                        <button onClick={this.addNewHandler} type="button"  className="btn btn-rounded-custom btn-primary table-btn" >Add New </button>
                    </div>
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default Summary;
