import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import CKEditors from "react-ckeditor-component";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


const EmailTemplates = (props) => {

    const [style, setStyle] = useState("custom-spinner")
    const [backToIndex, setBackToIndex] = useState(false)
    const [templatesData, setTemplatesData] = useState({})
    const [templatesList, setTemplatesList] = useState([])
    const [name, setName] = useState("")
    const [subject, setSubject] = useState("")
    const [updateClicked, setUpdateClicked] =  useState(false)
    const [body, setBody] = useState("")

    const headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    useEffect (()=> {
        if (JSON.parse(localStorage.getItem('creds')).user_type !== "admin") {
            setBackToIndex(true);
            return
        }
        getEmailTemplates();
        
    },[])

    const getEmailTemplates = () => {
        setStyle("custom-spinner")

        axios.get(`${process.env.REACT_APP_API_URL}getTemplates`, headers)
        .then(response=>{
            const data = response.data;
            let items = [];
            let myTemplatesData = templatesData;
            data.map((row, index)=>{
                myTemplatesData[row.id] = {
                    body: row.body,
                    name: row.name,
                    subject: row.subject
                }
                return (items.push(
                    <div key={"templates"+index} className="template-container">
                        <label className="" onClick={templateSelectedHandler} data-id={row.id}>{row.name}</label>
                        <div onClick={deleteTemplate} data-id={row.id}> <i data-id={row.id} className="fa fa-times-circle"></i> </div>
                    </div>)
            )});
            setTemplatesData(myTemplatesData)
            setTemplatesList(items)
            setStyle("custom-spinner-hide")
            
        })
        .catch(err=>{
            setTemplatesData(null)
            setTemplatesList([])
            setStyle("custom-spinner-hide")
        });
    }

    const templateSelectedHandler = (evt) => {
        
        const data = templatesData;
        setBody(data[evt.target.getAttribute("data-id")]["body"])
        setName(data[evt.target.getAttribute("data-id")]["name"])
        setSubject(data[evt.target.getAttribute("data-id")]["subject"])
        setUpdateClicked(true);
    }

    const deleteTemplate = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to delete email template");
            return
        }

        confirmAlert({
            title: 'Are you sure you want to delete this email template?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        setStyle("custom-spinner")
                        const payLoad = {
                            id: evt.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        }

                        axios.post(`${process.env.REACT_APP_API_URL}deleteTemplate`, payLoad, headers)
                        .then(response=>{
                            toast.success("Successfully Deleted Template");
                            getEmailTemplates();
                            setStyle("custom-spinner-hide")
                            addNewHandler()
                        })
                        .catch(err=>{
                            toast.error("Failed to delete email template");
                            setStyle("custom-spinner-hide")
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    const editorOnChangeHandler = (evt) => {
        setBody(evt.editor.getData())
    }

    const addNewHandler = () => {
        setName("");
        setSubject("");
        setBody("");
        setUpdateClicked(false)
    }

    const saveHandler = () => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to update email template");
            return
        }
        setStyle("custom-spinner")
        if(!name || !body || !subject){
            toast.error("Template name, subject and body are mandatory");
            setStyle("custom-spinner-hide")
            return;
        }
        const payLoad = {
            name: name,
            body: body,
            subject: subject,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }


        axios.post(`${process.env.REACT_APP_API_URL}templateCreateUpdate`, payLoad, headers)
        .then(response=>{
            toast.success("Successfully saved email template");
            getEmailTemplates();
            addNewHandler();
        })
        .catch(err=>{
            toast.error("Failed to save email template");
            setStyle("custom-spinner-hide")
        });
    }


    let redirect = null;

    if(backToIndex){
        redirect = <Redirect to = {{
                pathname: "/dashboard"
        }} />;
    }
    return(
        
        <Fragment>
                <Breadcrumb parent="App" title="Email Templates" />
                <div className={"loader-box " + style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Templates</h5>
                            <button  className="button-custom btn shadow-2 btn-dark btn-width"
                                onClick={addNewHandler} style={{float: "right"}}>Add New</button>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label>
                                    <span>*</span>
                                    <input type="text" className="form-control" name="name"
                                        disabled={updateClicked}
                                        onChange={e => setName(e.target.value)} value={name}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Subject
                                    </label>
                                    <span>*</span>
                                    <input type="text" className="form-control" name="subject"
                                        onChange={e => setSubject(e.target.value)} value={subject}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <label>
                                        Body
                                    </label>
                                    <span>*</span>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={body}
                                        events={{
                                            "change": editorOnChangeHandler
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="templates row">
                                    {templatesList}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12" style={{textAlign: "center"}}>
                                    <button type="button" onClick={saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        {updateClicked? "UPDATE": "SAVE NEW"}
                                    </button>
                                </div>
                            </div>
                        
                        </CardBody>
                    </Card>

                    
                    <div className="col-md-1 col-sm-0"></div>
                </div>

            </Fragment>

    );
}

export default EmailTemplates;