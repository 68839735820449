import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}

	componentDidMount() {
		if(!this.props.location.state){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: JSON.parse(localStorage.getItem('creds')).id
			};
			axios.post(`${process.env.REACT_APP_API_URL}domainDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.ext || !this.state.data.domain || !this.state.data.tenant_id) {
            toast.error('Fields with * are mandatory!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateDomain`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/domains',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Domains" title="Details" parent_url="domains"  />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit Domain" : "Add Domain"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Extension
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="ext"
                                        onChange={this.inputChangeHandler} value={this.state.data.ext} 
                                        disabled={this.props.location.state.id}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Tenant Id
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="tenant_id"
                                        onChange={this.inputChangeHandler} value={this.state.data.tenant_id} 
                                        disabled={this.props.location.state.id}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        domain
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="domain"
                                        onChange={this.inputChangeHandler} value={this.state.data.domain}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
