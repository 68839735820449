import React from 'react'
import { useState, useEffect } from 'react'
import { MDBDataTableV5 } from 'mdbreact';

import axios from 'axios';

function Player(props) {
    const videoRef = React.createRef()

    const [callData, setCallData] = useState([])
    const [callerId, setCallerId] = useState('')

    useEffect(() => {
        const payLoad = {
            action: "list_active_channels"
        }
        axios.post(`https://webtab-audiofork.saevolgo.com:8443/callActions`, payLoad)
        .then(response=>{
            populateList(response.data);
        })
        .catch(err=>{
            //Toast.error(err.message);
            console.log(err.message);
        });
    }, [])

    const populateList = (dataFromApi) => {
        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Caller',
                field: 'Caller',
            },
            {
                label: 'Callee',
                field: 'Callee',
            },
            {
                label: 'Aor',
                field: 'Aor',
            },
            {
                label: 'StartedAt',
                field: 'StartedAt',
            },
            {
                label: 'SIPCallID',
                field: 'SIPCallID',
            },
            {
                label: 'Hear',
                field: 'hear',
                sort: 'disabled',
            }
        ];

        let dataModified = [];
        if(dataFromApi != null)
        {
            dataModified = dataFromApi.map((row)=>{
                //let blf = (<i className="fas fa-id-card data-table-icon" onClick={this.openBlfHandler} data-id={row.id} data-id2={row.subscriber_id} data-companyName={row.company}/>)
                return ({...row, hear:  <div className="edit-column">
                <i className="fa fa-headphones data-table-icon"
                    onClick={e => setCallerId(row.SIPCallID)} data-id={row.SIPCallID}></i> 
                </div>});
            });

        }
        setCallData({columns: columns, rows: dataModified})
    }

    useEffect(() => {
        if(callerId === ''){
            return;
        }
        const peerConnection = new RTCPeerConnection() // eslint-disable-line

        peerConnection.ontrack = function (event) {
            videoRef.current.srcObject = event.streams[0]
            console.log(event.streams)
        }

        peerConnection.addTransceiver('audio')
        peerConnection.addTransceiver('audio')
        // peerConnection.addTransceiver('video')

        peerConnection.createOffer().then(offer => {
            peerConnection.setLocalDescription(offer)

            fetch(`https://webtab-audiofork.saevolgo.com:8443/monitor/${callerId}`, {
                method: 'POST',
                body: offer.sdp,
                headers: {
                    'Content-Type': 'application/sdp'
                }
            }).then(r => {
                return r.text()
            }).then(answer => {
                peerConnection.setRemoteDescription({
                    sdp: answer,
                    type: 'answer'
                })
            })
        })

        return function cleanup() {
            peerConnection.close()
        }
    }, [videoRef, callerId])

    return (
        <div>

            <div className = "MainIndex">
                <div className="users-table">
                <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                pagesAmount={4} data={callData} searchTop  searchBottom={false} />

                </div>
            </div>

            <video
                ref={videoRef}
                autoPlay
                muted
                controls
                playsInline
                className='mx-auto h-full'
            />
        </div>
        
    )
}

export default Player