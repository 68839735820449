import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import moment from 'moment';

import '../../assets/scss/app-styles/logs.scss'


class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedDomain: "",
            allDomainsCheckedList: "",
            logsPopulated: false,
            totalResults: 0,
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }
    levelItems = [
        <option value={""}>
        </option>,
        <option value="error">
            Error
        </option>,
        <option value="info">
            Info
        </option>,
    ];
    timeItems = [
        <option value={""}>
        </option>,
        <option value="5,m">
            Last 5 mintues
        </option>,
        <option value="15,m">
            Last 15 mintues
        </option>,
        <option value="30,m">
            Last 30 mintues
        </option>,
        <option value="1,h">
            Last 1 hour
        </option>,
        <option value="3,h">
            Last 3 hours
        </option>,
        <option value="6,h">
            Last 6 hours
        </option>,
        <option value="12,h">
            Last 12 hours
        </option>,
        <option value="24,h">
            Last 24 hours
        </option>,
        <option value="2,d">
            Last 2 days
        </option>,
        <option value="7,d">
            Last 7 days
        </option>,
        <option value="30,d">
            Last 30 days
        </option>,
        <option value="90,d">
            Last 90 days
        </option>,
    ];

    componentDidMount () {
        this.setState({style: 'custom-spinner'});
        this.filterHandler();
    }
    filterHandler = () => {
        let payLoad = {

        }
        if(this.state.message){
            payLoad["message"] = encodeURI(this.state.message);
        }
        if(this.state.level){
            payLoad["level"] = this.state.level;
        }
        if(this.state.time){
            const timeArray = this.state.time.split(",");
            const date = new Date();
            const endDate = moment(date).valueOf();
            const startDate = moment(date).subtract(parseInt(timeArray[0]),timeArray[1]).valueOf()

            payLoad["time"] = "start="+startDate* 1000000+"&end="+endDate* 1000000
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}getlogs`, payLoad, this.headers)
        .then(response=>{

            this.setState({logs: response.data});
            if(response.data.status === "success"){
                this.createLogs(response.data);
            } else {
                toast.error("Unable to get the data");
            }
            
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    hideShowLogs = (evt) => {

        const id = evt.target.getAttribute("data-id");
        const icon = document.getElementById("icon-"+id);
        if (icon.classList.contains('fa-angle-down')){
            // this means I have to show.
            icon.classList.remove('fa-angle-down');
            icon.classList.add('fa-angle-up');
            const elementToShow = document.getElementById(id);
            elementToShow.style.display = "block";
        } else {
            // this means I have to hide.
            
            icon.classList.remove('fa-angle-up');
            icon.classList.add('fa-angle-down');
            const elementToHide = document.getElementById(id);
            elementToHide.style.display = "none";
        }

    }

    logsList = []

    createLogs = (logs) => {
        this.setState({totalResults: logs.data.result.length});
        if(logs.data.result.length === 0){
            //toast.error("There are no Logs to Display");
            this.setState({allLogs: []});
            return;
        }
        const results = logs.data.result;
        let logsList = results.map((result, key) => {
            //const rawData = value[1].replace("@timestamp", "timestamp")
            const data = result.stream;
            let color = "red";
            if(data.level === "info") {
                color ="rgb(126, 178, 109)";
            } else if(data.level === "panic"){
                color ="orange";
            }
            return (

                <div className="row main-child" style={{borderLeft: "4px solid "+color}}>
                    <div className = "col-md-3 col-3">
                        <i id ={"icon-"+result.values[0][0]} className="fa fa-angle-down pull-down" onClick={this.hideShowLogs} data-id ={result.values[0][0]}/>
                        <span> {moment(data._timestamp).format('DD-MM-YYYY HH:MM:SS')}  </span>
                    </div>
                    <div className = "col-md-9 col-9 log-heading" >
                        <p> {data.msg} </p>
                    </div>
                    <div id={result.values[0][0]} className="col-md-12 col-12 detail-outer" style={{display:"none"}}>
                        <div className="col-md-12 col-12 log-details"> 
                            <p className="heading"> Log Labels </p>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>filename</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.filename}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>host</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.host}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>job</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.job}</p>
                                </div>
                            </div>



                            <p className="heading"> Detected fields </p>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>timestamp</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data._timestamp}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>caller</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.caller}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>functionName</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.functionName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>level</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.level}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>error</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.error}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>msg</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.msg}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-3"> 
                                    <p>stacktrace</p>
                                </div>
                                <div className="col-md-9 col-9"> 
                                    <p>{data.custom_panic_error_trace ? data.custom_panic_error_trace :data.stacktrace}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        this.logsList = logsList;
        this.setState({allLogs: logsList});
        this.setState({logsPopulated: true})

    }

    inputChangeHandler = (event) => {
		this.setState({ [event.target.name]:  event.target.value});
	}

    

    render () {
        return (
            <Fragment>
                <Breadcrumb parent="App" title="System Logs" />

                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                
                <div className="container-fluid main-container" style={{paddingRight: "50px"}}>
                    <div className ="col-md-12 col-sm-12 main-div" >
                        <div className="row form-group">
                            <div className="col-md-12" style={{marginBottom:"10px"}}>
                                <label>
                                    Message
                                </label>
                                <input type="text" className="form-control" name="message"
                                    onChange={this.inputChangeHandler} value={this.state.message}/>
                            </div>
                            <div className="col-md-4 col-12">
                                <label>
                                    Level
                                </label>
                                <select className="form-control" onChange={this.inputChangeHandler} value={this.state.level} name="level">

                                    {this.levelItems}

                                </select>
                            </div>
                            <div className="col-md-4 col-12">
                                <label>
                                    Time
                                </label>
                                <select className="form-control" onChange={this.inputChangeHandler} value={this.state.time} name="time">

                                    {this.timeItems}

                                </select>
                            </div>
                            <div className="col-md-4 col-12">
                                <button type="button" onClick={this.filterHandler} className="button-custom btn shadow-2 btn-default">
                                    FILTER
                                </button>
                            </div>
                        </div>

                        <div className="col-md-12 col-12 results-header"> 
                            {"Total Logs Found: "+ this.state.totalResults} 
                        </div>

                        {
                            this.state.allLogs?
                                this.state.allLogs
                            :null
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
    
};

export default Logs;