import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Auxi from '../../hoc/Auxi'

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	state = {
		data: {
		},
		backToIndex: false,
        style: 'custom-spinner',
        resellersList: [],
        
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler =(event)=> {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}

	componentDidMount() {
		if(!this.props.location.state){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        this.getResellers()
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: JSON.parse(localStorage.getItem('creds')).id
			};
			axios.post(`${process.env.REACT_APP_API_URL}resellerUserDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
                this.refreshJunctionList(data.id)
				this.setState({ data });
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

    getResellers = () => {
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}resellerSummary`, payLoad, this.headers)
        .then(response=>{
            let items = [];
            response.data.map((row, index)=>{
                const value = {id: row.id, name: row.name}
                items.push(value);
                return null
            });
            this.setState({resellersList: items});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

	saveHandler = (event) => {
		event.preventDefault();
        console.log(this.state.data)
		if (!this.state.data.name || !this.state.data.email) {
            toast.error('Fields with * are mandatory!');
            return;
		}

        if(!this.props.location.state.id && !this.state.data.password){
            toast.error('Password is mandatory to add reseller user!');
            return;
        }

        if(!this.props.location.state.id && this.state.data.password.length < 5){
            toast.error('Password should be atleast 6 characters long!');
            return;
        }

		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateResellerUser`, payLoad, this.headers)
		.then(response => {
			let data = this.state.data;
            data = response.data
            this.setState({data})
            toast.success("Successfully Updated User Information!")
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler =()=> {
		this.setState({ backToSummary: true });
	}
    resellerChangeHnadler = (result)=> {
        if(result.length === 0){
            this.setState({selectedReseller: ""});
            return;
        }
        this.setState({selectedReseller: result[0].id.toString()});
    }

    addNewHandler = (evt) => {
        this.setState({style: 'custom-spinner'});
        if (!this.state.selectedReseller && !this.state.selectedReseller){
            toast.error("Please select the reseller first! ")
            this.setState({style: 'custom-spinner-hide'});
            return
        }
        const payLoad = {
            user_id: this.state.data.id,
            reseller_id: this.state.selectedReseller
        };

        axios.post(`${process.env.REACT_APP_API_URL}createUpdateResellerUsersJunction`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            this.refreshJunctionList(this.state.data.id)
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

    refreshJunctionList = (userId) => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: userId
        };

        axios.post(`${process.env.REACT_APP_API_URL}getUsersToResellers`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Reseller',
                field: 'name',
            },
            
            {
                label: 'Delete',
                field: 'delete',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataModified = dataFromApi.map((row)=>{
                return ({...row, delete:  <div className="edit-column">
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.deleteHandler} data-id={row.id}></i>
                </div>});
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    deleteHandler = (event) =>{

        confirmAlert({
            title: 'Are you sure you want to remove this reseller from this user?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteResellerUserJunction`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshJunctionList(this.state.data.id)
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/reseller_users',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Users" title="Details" parent_url="users"  />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit User" : "Add User"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="name"
                                        onChange={this.inputChangeHandler} value={this.state.data.name}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email"
                                        onChange={this.inputChangeHandler} value={this.state.data.email}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                
                                {
                                    !this.state.data.id ?
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Password
                                            </label> <span> * </span>
                                            <input type="password" className="form-control" name="password"
                                                onChange={this.inputChangeHandler} value={this.state.data.password}/>
                                        </div>
                                    : null
                                }
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
                {
                    this.state.data.id? 
                    <Auxi>
                        <div className="container-fluid main-container">
                            <div className="col-md-2 col-sm-0"></div>
                            <Card className="col-md-8 col-sm-12">
                                <CardHeader>
                                    <h5>Resellers</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div id="the-basics">
                                        <form className="theme-form" style={{width: "calc(100% - 166px)"}}>
                                            <div className="form-group">
                                                <i className="fa fa-search type-ahead-search-icon"/>
                                                <Typeahead
                                                    id="basic-typeahead"
                                                    className="search-domains"
                                                    labelKey="name"
                                                    multiple={false}
                                                    options={this.state.resellersList}
                                                    onChange={this.resellerChangeHnadler}
                                                    placeholder="Choose a reseller..."
                                                />
                                            </div>
                                            
                                        </form>
                                        <button className="btn btn-primary btn-reseller-add"
                                        type="button" onClick={this.addNewHandler}>Add</button>
                                    </div>
                                </CardBody>
                            </Card>

                            
                            <div className="col-md-2 col-sm-0"></div>
                        </div>

                        <div className="container-fluid main-container">
                            <div className="col-md-1 col-sm-0"></div>
                            <Card className="col-md-10 col-sm-12">
                                <CardHeader>
                                    <h5>User Part of Resellers</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic MainIndex">
                                    <div className="users-table">
                                        <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                                        pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                                    </div>
                                </CardBody>
                            </Card>

                            <div className="col-md-1 col-sm-0"></div>
                        </div>

                        <div className = "MainIndex">
                            
                        </div>
                    </Auxi>
                : null

                }
            </Fragment>
		);

	}
}

export default Details;
