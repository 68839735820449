import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, Settings, LogOut } from 'react-feather';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {EditProfile} from '../../../constant'

const UserMenu = ({ history }) => {

    const [profile, setProfile] = useState('');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
    }, []);

    const logoutHandler = () =>  {

        localStorage.removeItem('profileURL')
        localStorage.removeItem('token');
        localStorage.setItem("authenticated",false)
        history.push(`${process.env.PUBLIC_URL}/login`)
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={profile && profile!== "null" ?process.env.REACT_APP_API_URL + (profile.charAt(0) === "/" ? profile.substring(1): profile): man } alt="header-user" />
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to={`${process.env.PUBLIC_URL}/userEdit`}><User />{EditProfile}</Link></li>
                    <li><a><Settings />{"Settings"}</a></li>
                    <li><a onClick={logoutHandler} ><LogOut /> {"Log out"}</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default withRouter(UserMenu);