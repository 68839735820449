import React, {useEffect} from 'react';
import CKEditors from "react-ckeditor-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

import '../../assets/scss/app-styles/qr-modal.scss'

const QRModal = (props) => {

    return(
        <>

            <Modal isOpen={props.show} toggle={props.toggleHandler}>
                <ModalHeader toggle={props.toggleHandler}>{"Email QR"}</ModalHeader>
                <ModalBody>
                    {
                        props.linked === "true"?
                            <form>

                                <div className="form-group">
                                    <label className="col-form-label" htmlFor="message-text">{"Email:"}</label>
                                    <input type="text" onChange={props.onChangeHandler} className="form-control" name="to" value={props.to}/>

                                    <label className="col-form-label" htmlFor="message-text">{"CC:"}</label>
                                    <input type="text" onChange={props.onChangeHandler} className="form-control" name="cc" value={props.cc}/>

                                    <label className="col-form-label" htmlFor="message-text">{"Subject:"}</label>
                                    <input type="text" onChange={props.onChangeHandler} className="form-control" name="subject" value={props.subject}/>
                                </div>

                                <div className="form-group">
                                    {
                                        props.showTemplateSave?
                                            <span className="template-add-btn" onClick={props.toggleTemplateSave}><i className="fa fa-minus-circle"/> </span>
                                        :
                                            <span className="template-add-btn" onClick={props.toggleTemplateSave}><i className="fa fa-plus-circle"/> </span>
                                    }
                                    
                                    {
                                        props.showTemplateSave?
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <input type="text" onChange={props.onChangeHandler} placeholder="Template Name" 
                                                        className="form-control" name="template_name" value={props.template_name} 
                                                        disabled={props.templateSelected}/>
                                                </div>
                                                <div className="col-md-4">
                                                    <Button className="template-save-btn" color="dark" onClick={props.saveTemplateHandler}>
                                                        {props.templateSelected ? "Update" : "Save"}
                                                    </Button>
                                                </div>
                                                
                                            </div>
                                        : null
                                    }
                                    <label className="col-form-label" htmlFor="message-text">{"Body:"}</label>
                                    <div className="qr-editor">

                                        <CKEditors
                                            activeclassName="p10"
                                            content={props.body}
                                            events={{
                                                "change": props.editorOnChangeHandler
                                            }}
                                        />

                                    </div>

                                    <div className="templates row">
                                        {props.templatesList}
                                    </div>
                                    
                                </div>
                            </form>
                            
                        :
                            <form>
                                <div className="form-group">
                                    <label className="col-form-label" >{"Email:"}</label>
                                    <input type="text" onChange={props.onChangeHandler} 
                                        className="form-control" name="to" value={props.to}/>

                                    <label className="col-form-label">{"DID:"}</label>
                                        <input type="text" onChange={props.onChangeHandler} 
                                        className="form-control" name="did" value={props.did}/>

                                    <label className="col-form-label">{"Pin:"}</label>
                                    <input type="text" onChange={props.onChangeHandler} 
                                        className="form-control" name="pin" value={props.pin}/>
                                    {
                                        !props.domainExists?
                                            <>
                                                <label className="col-form-label">{"Domain:"}</label>
                                                <select  className="form-control"
                                                    style={{width: "calc(100% - 26px)"}}
                                                    onChange={props.onChangeHandler} name="domain" value={props.domain}>
                                                    {props.domainItems}
                                                </select>
                                                <FontAwesomeIcon 
                                                    icon={faSync} className="custom-data-table-icon domain-refresh"
                                                    title="Sync Domains List"
                                                    onClick={props.syncHandler}/>
                                            </>
                                        :null
                                    }

                                    <label className="col-form-label">{"Password:"}</label>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <input type="text" onChange={props.onChangeHandler} 
                                            className="form-control" name="password" value={props.password}/>
                                        </div>
                                        <div className="col-md-4">
                                            <Button color="warning" onClick={props.autoGeneratePassword}
                                            style={{padding: "7px 16px"}}>
                                                Auto Generate
                                            </Button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </form>
                    }
                    
                </ModalBody>
                <ModalFooter>
                    {
                        props.linked === "true"?
                            <Button color="primary" onClick={props.sendEmailHandler}>{"Send Email"}</Button>
                        :
                            <Button color="primary" onClick={props.linkHandler}>{"Link"}</Button>
                    }   
                    
                    <Button color="secondary" onClick={props.toggleHandler}>{"Cancel"}</Button>
                </ModalFooter>
            </Modal>
        </>

    )};

export default QRModal;
